<template>
  <div class="view-home">
      <div class="forums-block">
        <div class="container">
          <nav class="breadcrumbs-block">
            <ul class="breadcrumbs-list">
              <li class="breadcrumbs-item"><router-link to="/">{{ $t('navbar.home') }}</router-link></li>
              <li class="breadcrumbs-item"><router-link to="/events">{{ $t('events-main') }}</router-link></li>
              <li class="breadcrumbs-item">{{ $t('forums') }}</li>
            </ul>
          </nav>
          <div class="events-title">
            {{ $t('forums') }}
          </div>
          <div class="events-title-line">
            <svg width="95" height="4" viewBox="0 0 95 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line y1="2" x2="95" y2="2" stroke="#1090CB" stroke-width="4"/>
              <line y1="2" x2="95" y2="2" stroke="#42A7D6" stroke-width="4"/>
            </svg>
          </div>
        </div>
        <div class="forums-section">
          <div class="container">
            <div class="forum-slide-item">
              <div class="forum-slide-title">
                {{forumsList[0][`name_${$i18n.locale}`] || forumsList[0].name_ru}}
              </div>
              <div class="forum-slide-text">
                {{forumsList[0][`description_${$i18n.locale}`] || forumsList[0].description_ru}}
              </div>
              <div class="forum-slide-details">
                <div>
                  <span>{{ $t('datesOfEvent') }}:</span> {{formattedDate(forumsList[0].date_from, forumsList[0].date_to)}}
                </div>
                <div>
                  <span>{{ $t('venue') }}:</span> {{forumsList[0][`venue_${$i18n.locale}`] || forumsList[0].venue_ru}}
                </div>
              </div>
              <router-link :to="/one-forum/ + forumsList[0].id" class="forum-slide-link">
                {{ $t('findMore') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    <div class="forums-filters-block forums-img-back">
      <div class="container">
        <div class="forums-filters">
          <div class="filter-period">
            <el-select v-model="nameForum" :placeholder="$t('nameTitle')" class="filter-date-select" @change="handleName">
              <el-option
                  v-for="item in namesList"
                  :key="item.id"
                  :label="item[`name_${$i18n.locale}`]"
                  :value="item.name">
              </el-option>
            </el-select>
          </div>
          <div class="filter-date date-icon">
            <el-select v-model="dateForum" :placeholder="$t('dateOfEvent')" class="filter-date-select" @change="handleDate">
              <el-option
                  v-for="item in datesList"
                  :key="item.id"
                  :label="item[`name_${$i18n.locale}`] || item.name_ru"
                  :value="item.date_from">
              </el-option>
            </el-select>
          </div>
          <div class="filter-place">
            <el-select v-model="placeForum" :placeholder="$t('venue')" class="filter-date-select" @change="handlePlace">
              <el-option
                  v-for="item in placesList"
                  :key="item.id"
                  :label="item.place"
                  :value="item.place">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>
    <div class="forum-list-block">
      <div class="container">
        <transition-group name="fade" tag="div" class="forum-list">
          <div class="forum-list-item" v-for="(item, index) in displayedItems" :key="index">
            <div class="forum-list-item-img">
              <el-image
                  style="width: 100%; height: 100%"
                  :src="item.banner"
                  fit="fit"></el-image>
            </div>
            <div class="forum-list-item-info">
              <div class="forum-list-item-date">
                {{ $t('datesOfEvent') }}: {{ formattedDate(item.date_from, item.date_to) }}
              </div>
              <div class="forum-list-item-title">
                {{ item[`name_${$i18n.locale}`] || item.name_ru }}
              </div>
              <div class="forum-list-item-text">
                {{ item[`description_${$i18n.locale}`] || item.description_ru }}
              </div>
              <div class="forum-list-item-address">
                {{ $t('venue') }}: {{ item[`venue_${$i18n.locale}`] || item.venue_ru}}
              </div>
              <router-link :to="/one-forum/ + item.id" class="forum-list-item-link">
                {{ $t('findMore') }}
              </router-link>
            </div>
          </div>
        </transition-group>
        <button v-if="canLoadMore" class="forum-list-btn" @click="loadMore">Загрузить ещё</button>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  mounted() {
    this.$http.get(window.API_ROOT + '/api/events/forum/list')
        .then(res => {
          this.forumsList = res.body

          this.collectDates()
          this.collectNames()
          this.collectPlaces()
          this.displayedItems = this.forumsList.slice(0, 2);
        })
  },
  filters: {

  },
  methods: {
    formattedDate(date_from, date_to) {
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      const dateFrom = new Date(date_from).toLocaleDateString('ru-RU', options);
      const dateTo = new Date(date_to).toLocaleDateString('ru-RU', options);
      return `${dateFrom} - ${dateTo} гг`;
    },
    collectPlaces() {
      const uniqueSet = new Set();

      this.placesList = this.forumsList.reduce((acc, forum) => {
        if (!uniqueSet.has(forum.venue_ru)) {
          uniqueSet.add(forum.venue_ru);
          acc.push({
            id: forum.id,
            place: forum[`venue_${this.$i18n.locale}`] || forum.venue_ru,
          });
        }
        return acc;
      }, []);
    },
    collectNames() {
      this.namesList = this.forumsList.map(item => ({name: item[`name_${this.$i18n.locale}`] || item.name_ru, id: item.id }))
    },
    collectDates() {
      const uniqueSet = new Set();
      this.datesList = this.forumsList.reduce((acc, item) => {
        const date = new Date(item.date_from);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const formattedDate = `${day < 10 ? '0' + day : day}.${month < 10 ? '0' + month : month}.${year}`;

        if (!uniqueSet.has(formattedDate)) {
          uniqueSet.add(formattedDate);
          acc.push({
            id: item.id,
            date_from: item.date_from,
            name: formattedDate
          });
        }
        return acc;
      }, []);
    },
    loadMore() {
      const nextItems = this.forumsList.slice(this.displayedItems.length, this.displayedItems.length + 2);
      this.displayedItems = [...this.displayedItems, ...nextItems];
    },
    handleName() {
      this.displayedItems = this.forumsList.filter(item => (item[`name_${this.$i18n.locale}`] || item.name_ru) == this.nameForum)
      this.dateForum = ''
      this.placeForum = ''
    },
    handleDate() {
      this.displayedItems = this.forumsList.filter(item => item.date_from == this.dateForum)
      this.placeForum = ''
      this.nameForum = ''
    },
    handlePlace() {
      this.displayedItems = this.forumsList.filter(item => (item[`venue_${this.$i18n.locale}`] || item.venue_ru) == this.placeForum)
      this.nameForum = ''
      this.dateForum = ''
    }
  },
  computed: {
    canLoadMore() {
      return this.displayedItems.length < this.forumsList.length && !this.nameForum && !this.dateForum && !this.placeForum;
    },
  },
  data() {
    return {
      displayedItems: [],
      placesList: [],
      namesList: [],
      datesList: [],
      forumsList: [],
      showMore: false,
      options: [{
        value: 'Option1',
        label: 'Option1'
      }, {
        value: 'Option2',
        label: 'Option2'
      }, {
        value: 'Option3',
        label: 'Option3'
      }, {
        value: 'Option4',
        label: 'Option4'
      }, {
        value: 'Option5',
        label: 'Option5'
      }],
      nameForum: '',
      dateForum: '',
      placeForum: '',
    }
  }
}
</script>
<style>

.forums-block {
  padding-top: 75px;
  background: linear-gradient(90deg, #32436d 0%, #02c7fa 100%);
}

.forums-block .events-title-line {
  margin-bottom: 20px;
}

.forums-block .forum-slide-item {
  position: relative;
  z-index: 10;
}

.events-title {
  font-size: 40px;
  font-weight: 600;
  line-height: 60px;
  color: #FFF;
  margin-bottom: 15px;
  font-family: "Montserrat", sans-serif;
}

.events-section-title {
  font-size: 36px;
  font-weight: 700;
  line-height: 57.6px;
  color: #FFF;
  margin-top: 50px;
  margin-bottom: 40px;
}

.forums-section {
  height: 700px;
  padding: 80px 0;
  background-repeat: no-repeat;
  background-image: url("/images/forum-section-img.png");
  background-size: 100% auto;
  position: relative;
}

.forums-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #32436DA8;
  z-index: 0;
}

.all-forums {
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  color: #FFF;
  border-bottom: 1.5px solid #FFF;
  display: inline-flex;
  width: 125px;
  text-transform: uppercase;
  margin-bottom: 21px;
  position: relative;
  z-index: 10;
}

a {
  text-decoration: none;
}

a:hover {
  color: #FFF;
  text-decoration: none;
}

.forum-slide-item {
  display: flex;
  flex-direction: column;
  gap: 21px;
}

.forum-slide-title {
  font-size: 30px;
  font-weight: 800;
  line-height: 45px;
  color: #FFF;
  width: 65%;
  text-shadow: 1px 1px 2px black;
}

.forum-slide-text {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: #FFF;
  width: 65%;
  text-shadow: 1px 1px 2px black;
}

.forum-slide-details {
  font-size: 20px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: -0.015em;
  color: #FFF;
  text-shadow: 1px 1px 2px black
}

.forum-slide-details span {
  font-weight: 600;
}


.filter-date input::placeholder {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.01em;
  color: #737373;
}

.filter-date input {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.01em;
  color: #737373;
  padding-left: 44px;
}

.filter-date {
  position: relative;
}

.filter-date::before {
  position: absolute;
  content: "";
  width: 24px;
  height: 24px;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 10;
  background-image: url("/images/filter-date.svg");
}

.filter-period {
  position: relative;
}

.filter-period::before {
  position: absolute;
  content: "";
  width: 24px;
  height: 24px;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 10;
  background-image: url("/images/filter-period.svg");
}

.filter-period input::placeholder {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.01em;
  color: #737373;
}

.filter-period input {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.01em;
  color: #737373;
  padding-left: 44px;
}

.filter-place {
  position: relative;
}

.filter-place::before {
  position: absolute;
  content: "";
  width: 24px;
  height: 24px;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 10;
  background-image: url("/images/filter-place.svg");
}

.filter-place input::placeholder {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.01em;
  color: #737373;
}

.filter-place input {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.01em;
  color: #737373;
  padding-left: 44px;
}

.forums-filters {
  display: flex;
  gap: 25px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.forum-list {
  display: flex;
  justify-content: space-between;
  padding-bottom: 40px;
  flex-wrap: wrap;
}

.forum-list-item {
  width: 49%;
  display: flex;
  flex-direction: column;
  gap: 18px;
  box-shadow: 0px 3.63px 139.92px 0px #0000000A;
  padding: 25px 0px;
}

.forum-list-item-img {
  height: 293px;
  border-radius: 6px;
}

.forum-list-item-img img {
  border-radius: 6px;
}

.forum-list-item-date {
  font-size: 18.55px;
  font-weight: 400;
  line-height: 31.8px;
  letter-spacing: -0.015em;
  color: #9497A1;
}

.forum-list-item-title {
  font-size: 25.72px;
  font-weight: 800;
  line-height: 38.57px;
  color: #32436D;
  font-family: "Montserrat", sans-serif;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.forum-list-item-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.015em;
  color: #9497A1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.forum-list-item-address {
  font-family: "Open Sans", sans-serif;
  font-size: 17.14px;
  font-weight: 400;
  line-height: 28.29px;
  letter-spacing: -0.015em;
  color: #1090CB;
}

.forum-list-item-link {
  font-size: 13.72px;
  font-weight: 500;
  line-height: 20.57px;
  letter-spacing: -0.015em;
  color: #FFF;
  background-color: #1090CB;
  border-radius: 12px;
  padding: 12px 70px;
  cursor: pointer;
  width: fit-content;
  transition: transform 0.5s ease;
}

.forum-list-item-link:hover {
  transform: scale(1.05);
  text-decoration: none;
  color: #FFF;
}

.forum-list-btn {
  font-size: 16px;
  font-weight: 400;
  line-height: 32.69px;
  color: #1090CB;
  border-radius: 12px;
  border: 1.33px solid #1090CB;
  padding: 12px 70px;
  background: #FFF;
  margin: 0 auto;
  display: block;
  margin-bottom: 120px;
  transition: transform 0.5s ease;
}

.forum-list-btn:hover {
  transform: scale(1.05);
  text-decoration: none;
  color: #1090CB;
}

.forum-list-item-info {
  padding: 0 35px;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.el-select-dropdown {
  max-width: 400px;
}

@media screen and (max-width: 450px) {
  .el-select-dropdown {
    max-width: 92%;
  }
}

</style>
